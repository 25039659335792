import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import LinkButton from "../pohrby/LinkButton";
import careRating from "../../assets/img/careRating.svg";
import careRating2 from "../../assets/img/careRating2.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Quotes from "../../assets/img/quotes.svg";
import Rozpis from "./Rozpis";


SwiperCore.use([Autoplay]);


const TrustInner = styled.section`
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
  /* background: #F4F3F0; */
  background: #FBFBFA;
  padding-top: 250px;

  @media (max-width: 899px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .recenze {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    overflow: hidden;
    max-width: 735px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 899px) { 
      display: block;
      max-width: 230px;
      padding: 20px;
    }

    .left {
      display: flex;
      align-items: center;

      .leftInner {
        max-width: 560px;
        margin: 0 auto;
        display: block;
        padding: 35px 0;
        width: calc(100% - 70px);

        @media (max-width: 899px) { 
          width: calc(100%);
          padding: 0;
        }

        img {
          height: 25px;

          @media (max-width: 899px) { 
            height: 20px;
          }
        }

        p {
          font-family: NewKansas;
          font-size: 22px;
          color: #000000;
          line-height: 32px;
          margin-top: 15px;
          margin-bottom: 20px;

          @media (max-width: 899px) { 
            font-size: 16px;
            line-height: 25px;
          }

          span {
            font-family: NewKansas-Bold;
          }
        }

        .zakaznik {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
        }
      }
    }

    .right {
      width: calc(210px);
      background: #e2e1d7;
      

      @media (max-width: 899px) { 
        width: 100%;
        display: flex;
        align-items: center;
        background: none;
      }

      span {
        display: block;

        &:first-child {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #000000;
          line-height: 27px;  
        }

        &:last-child {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #5B5B5B;
          line-height: 27px;
        }
      }

      .obrazek {
        height: 100% !important;
        width: 100% !important;
      }

      .obrazekSmall {
        margin-right: 20px;
        border-radius: 100%;
      }
    }
  }

  h2 {
    margin: 0 auto;
    font-family: NewKansas;
    font-size: 36px;
    color: #332A0D;
    text-align: center;
    line-height: 46px;
    margin-bottom: 25px;
    width: calc(100% - 100px);
    max-width: 900px;

    @media (max-width: 899px) {
      max-width: 600px;
      width: calc(100% - 50px);
      font-size: 25px;
      line-height: 35px;
      text-align: left;
    }
  }

  .desc {
    margin: 0 auto;
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #666666;
    text-align: center;
    line-height: 25px;
    margin-bottom: 30px;
    width: calc(100% - 100px);
    max-width: 800px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
      text-align: left;
    }
  }

  .hodnoceni {
    margin: 0 auto;
    display: block;

    @media (max-width: 899px) {
      margin-left: calc((100% - 600px)/2);
    }

    @media (max-width: 649px) {
      margin-left: 25px;
    }
  }

  .swiper-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
`;





const Trust = ({ setModal }) => {
  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });

  let pocitadlo = 0;

  

  return (
    <StaticQuery
      query = {graphql`
        query {
          careTeam: file(relativePath: { eq: "careTeam.png" }) {
            childImageSharp {
              fixed(height: 280, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }


          careZakaznice1: file(relativePath: { eq: "recenze2a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice2: file(relativePath: { eq: "recenze1a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice3: file(relativePath: { eq: "recenze3a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice4: file(relativePath: { eq: "recenze4a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice5: file(relativePath: { eq: "recenze5a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice6: file(relativePath: { eq: "recenze6a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careZakaznice7: file(relativePath: { eq: "recenze7a.jpg" }) {
            childImageSharp {
              fixed(height: 250, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small1: file(relativePath: { eq: "recenze2a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small2: file(relativePath: { eq: "recenze1a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small3: file(relativePath: { eq: "recenze3a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small4: file(relativePath: { eq: "recenze4a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small5: file(relativePath: { eq: "recenze5a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small6: file(relativePath: { eq: "recenze6a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          small7: file(relativePath: { eq: "recenze7a.jpg" }) {
            childImageSharp {
              fixed(height: 60, width: 60, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => {
        let recenzeData = [
          {
            recenzeText: <>Rychlost, spolehlivost, ochota, spolupráce, vše dokonalé. <span>Paní mě krok po kroku vedla, vše tak proběhlo v pořádku.</span></>,
            zakaznikJmeno: "Zita J.",
            zakaznikKdo: "Zákaznice",
            recenzeImgSmall: data.small1.childImageSharp.fixed,
            recenzeImg: data.careZakaznice1.childImageSharp.fixed,
          },
          {
            recenzeText: <>Pro mne a celou rodinu to byla velká úleva. <span>Žádné naše přání pro vás nebylo nereálné, všechno bylo tak jak mělo být</span>.</>,
            zakaznikJmeno: "Jan L.",
            zakaznikKdo: "Zákazník",
            recenzeImgSmall: data.small2.childImageSharp.fixed,
            recenzeImg: data.careZakaznice2.childImageSharp.fixed,
          },
          {
            recenzeText: <>Rychlost, profesionalita, ochota a vstřícnost. <span>Tento přístup byl pro nás v této těžké situaci skutečnou pomocí.</span></>,
            zakaznikJmeno: "Karel Č.",
            zakaznikKdo: "Zákazník",
            recenzeImgSmall: data.small3.childImageSharp.fixed,
            recenzeImg: data.careZakaznice3.childImageSharp.fixed,
          },
          {
            recenzeText: <>Když nám umřel můj táta, <span>bylo to velmi těžké období, asi bych nezvládla dál někam chodit a zařizovat.</span> Jsem moc ráda, že jsem vše mohla vyřídit s rodinou z domova.</>,
            zakaznikJmeno: "Hana V.",
            zakaznikKdo: "Zákaznice",
            recenzeImgSmall: data.small4.childImageSharp.fixed,
            recenzeImg: data.careZakaznice4.childImageSharp.fixed,
          },
          {
            recenzeText: <>Zaměstnanci Goodbye byli <span>maximálně ochotní, všechno nám vysvětlili a pomohli nám zvládnout situaci</span>, která byla od začátku pro celou rodinu velkým neštěstím. Děkujeme.</>,
            zakaznikJmeno: "Jana Č.",
            zakaznikKdo: "Zákaznice",
            recenzeImgSmall: data.small5.childImageSharp.fixed,
            recenzeImg: data.careZakaznice5.childImageSharp.fixed,
          },
          {
            recenzeText: <>Přestože jsem byla nucena řešit těžkou situaci, mohu s čistým svědomím říct, že služby <span>společnosti Goodbye byly perfektní.</span></>,
            zakaznikJmeno: "Tereza Š.",
            zakaznikKdo: "Zákaznice",
            recenzeImgSmall: data.small6.childImageSharp.fixed,
            recenzeImg: data.careZakaznice6.childImageSharp.fixed,
          },
          {
            recenzeText: <>Děkuju za rychlé řešení a zajištění obřadu v jablonecké smuteční síni. <span>V této nepříjemné situaci to pro nás byla velká úleva.</span></>,
            zakaznikJmeno: "Jiří F.",
            zakaznikKdo: "Zákazník",
            recenzeImgSmall: data.small7.childImageSharp.fixed,
            recenzeImg: data.careZakaznice7.childImageSharp.fixed,
          },
        ];
        
        return (
          <TrustInner>
            <h2 data-aos="new-animation" data-aos-delay="000">Stovkám rodin jsme už pomohli v jejich situaci</h2>
            {/* <p className="desc">Již 2 roky každý den pomáháme lidem se vypořádat se smrtí jejich blízkého. Organizujeme pohřby, píšeme závěti, jednáme s matrikou nebo např. připravujeme nemovitosti na prodej. Pro naše klieny uděláme první poslední. Díky tomu jsme také nejlépe hodnocenou společností v našem oboru.</p> */}
            
            {isLess899 ?
              <img className="hodnoceni" src={careRating2} alt="5/5 hodnocení" />
            :
              <img className="hodnoceni" src={careRating} alt="5/5 hodnocení" />
            }

            <Swiper loop={true} spaceBetween={isLess899 ? 25 : 40} width={isLess899 ? 270 : 735} centeredSlides freeMode autoplay={{delay: 1}} speed={12000} grabCursor={true} slidesOffsetBefore={100}>

              {recenzeData.map(r => {
                pocitadlo++;

                return (
                  <SwiperSlide key={pocitadlo}>
                    <div className="recenze">
                      <div className="left">
                        <div className="leftInner">
                          <img src={Quotes} alt="" />
                          <p>{r.recenzeText}</p>

                          {(!isLess899) &&
                            <span className="zakaznik">{r.zakaznikJmeno}, {r.zakaznikKdo}</span>
                          }
                        </div>
                      </div>
                      <div className="right">
                        {(isLess899) ?
                          <>
                            <Img className="obrazekSmall" alt={r.zakaznikKdo} fixed={r.recenzeImgSmall} />
                            <div className="textik">
                              <span>{r.zakaznikJmeno}</span>
                              <span>{r.zakaznikKdo}</span>
                            </div>
                          </>
                          :
                          <Img className="obrazek" alt={r.zakaznikKdo} fixed={r.recenzeImg} />
                        }
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>



            <Rozpis setModal={setModal} />

            
            

          </TrustInner>
        )
      }}
    />
  );
}

export default Trust;
