import React from "react";
import RozcestnikButton from "./RozcestnikButton";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import Sipka from "../assets/img/sipkaW.png";
import Img from "gatsby-image";



const RozcestnikInner = styled.section`
  background: #F4F3F0;
  padding: 60px 0;
  margin-top: 120px;

  @media (max-width: 900px) {
    padding: 40px 0;
    margin-top: 60px;
  }

  .rozcestikWrapper {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 900px) {
      display: block;
      width: calc(100% - 50px);
    }

    .box {
      background: #FFFFFF;
      box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      border-radius: 20px;
      padding: 30px;
      max-width: 290px;
      width: calc(100%/3 - 60px - 20px);

      @media (max-width: 1060px) {
        padding: 20px;
        width: calc(100%/3 - 50px);
      }

      @media (max-width: 900px) {
        width: calc(100% - 40px);
        margin: 0 auto;
        margin-bottom: 20px;
        max-width: 560px;
      }

      .obrazek {
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 15px;
        font-family: Visuelt-Medium;
        font-size: 22px;
        color: #000000;
      }

      p {
        margin-bottom: 25px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #5A5A5A;
        line-height: 21px;
      }

      a {
        display: block;
        background: #3A557C;
        border-radius: 5px;
        padding: 19px 25px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1060px) {
          padding: 19px 15px;
          text-align: center;
        }

        @media (max-width: 900px) {
          padding: 19px 25px;
          text-align: left;
          max-width: 260px;
        }

        &:hover {
          background: #2A4164;
          cursor: pointer;
        }

        span {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #FFFFFF;

          @media (max-width: 1060px) {
            text-align: center;
            width: 100%;
            display: block;
          }

          @media (max-width: 900px) {
            text-align: left;
          }
        }

        img {
          width: 20px;

          @media (max-width: 1060px) {
            display: none;
          }

          @media (max-width: 900px) {
            display: block;
          }
        }
      }
    }
  }
`;


const Rozcestnik = (props) => {
  return (
    <StaticQuery
      query = {graphql`
        query {
          zaveti: file(relativePath: { eq: "zavetiW.png" }) {
            childImageSharp {
              fixed(height: 100, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          pohrby: file(relativePath: { eq: "pohrbyW.png" }) {
            childImageSharp {
              fixed(height: 100, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }


          poradna: file(relativePath: { eq: "poradnaW.png" }) {
            childImageSharp {
              fixed(height: 100, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      



        }
      `}
      render={data => (
        <RozcestnikInner>
          <div className="rozcestikWrapper">

            {props.pohrby && 
              <div className="box">
                <Img className="obrazek" alt="Pohřební služba" fixed={data.pohrby.childImageSharp.fixed} />
                <h2>Pohřební služba</h2>
                <p>Pomůžeme vám naplánovat jedinečné rozloučení pro vašeho blízkého.</p>
                <Link to="/pohrebni-sluzba/">
                  <span>Naplánovat pohřeb</span>
                  <img src={Sipka} alt="" />
                </Link>
              </div>
            }

            {props.zaveti && 
              <div className="box">
                <Img className="obrazek" alt="Závěti online" fixed={data.zaveti.childImageSharp.fixed} />
                <h2>Závěti online</h2>
                <p>Vytvořte si závěť do 15 minut, bez notáře a z pohodlí vašeho domova.</p>
                <Link to="/zaveti/">
                  <span>Sepsat závěť</span>
                  <img src={Sipka} alt="" />
                </Link>
              </div>
            }

            {props.poradna && 
              <div className="box">
                <Img className="obrazek" alt="Poradna" fixed={data.poradna.childImageSharp.fixed} />
                <h2>Poradna</h2>
                <p>Připravili jsme pro vás desítky článků a návodů o všem co se smrti týče.</p>
                <Link to="/poradna/">
                  <span>Zjistit více</span>
                  <img src={Sipka} alt="" />
                </Link>
              </div>
            }

            {props.parte && 
              <div className="box">
                <Img className="obrazek" alt="Poradna" fixed={data.poradna.childImageSharp.fixed} />
                <h2>Parte online</h2>
                <p>Interaktivní smuteční oznámení: sdílejte vzpomínky, fotky, vyberete peníze.</p>
                <Link to="/parte/">
                  <span>Vytvořit zdarma</span>
                  <img src={Sipka} alt="" />
                </Link>
              </div>
            }
          </div>
        </RozcestnikInner>
      )}
    />
  );
}

export default Rozcestnik;
