import React from "react";
import styled from "styled-components";
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { useMediaQuery } from 'react-responsive';


/* import Podpora from "../assets/img/podpora.png"; */
import Phone from "../assets/img/telefon.svg";



const SupportPanelInner = styled.section`
  width: calc(100% - 50px);
  margin: 60px auto;

  @media (min-width: 900px) {
    width: calc(100% - 100px);
    margin: 90px auto;    
    max-width: ${(props => props.isWide ? "980px" : "820px")};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .supportLeft {
    h2 {
      font-family: NY-Medium, Helvetica;
      font-size: 25px;
      color: #000000;
      text-align: center;
      line-height: 35px;
      margin-bottom: 30px;

      @media (min-width: 450px) {
        br {
          display: none;
        }
      }

      @media (min-width: 900px) {
        font-family: NY-Medium, Helvetica;
        font-size: 32px;
        color: #000000;
        line-height: 45px;
        text-align: left;
        margin: 0;
        margin-bottom: 30px;
        max-width: ${(props => props.isPohreb ? "500px" : "none")};
      }
    }

    p {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: #545454;
      text-align: center;
      margin: 0 auto;
      line-height: 25px;
      margin-bottom: 30px;
      max-width: 600px;

      @media (min-width: 900px) {
        text-align: left;
        margin: 0;
        max-width: ${(props => props.isPohreb ? "500px" : "432px")};
      }
    }

    .contact {
      margin-top: 30px;

      @media (max-width: 899px) {
        display: block;
        margin-top: 25px;
        margin-bottom: 25px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      .phone {
        display: inline-block;
        margin-right: 25px;
        vertical-align: baseline;
        color: #5E5E5E;

        @media (max-width: 899px) {
          display: block;
          margin-bottom: 10px;
          margin-right: 0;
        }

        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-top: -2.5px;
        }

        span {
          font-family: Visuelt-Bold;
          font-size: 16px;
          color: #000000;
          vertical-align: middle;
          vertical-align: baseline;
          margin-left: 10px;
        }
      }

      .nonstop {
        display: inline-block;
        vertical-align: baseline;

        @media (max-width: 899px) {
          display: block;
        }

        .greenDot {
          background: #49B25D;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          animation: pulse 2s infinite;
          display: inline-block;
          vertical-align: middle;
        }

        @keyframes pulse {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0.25;
          }

          100% {
            opacity: 1;
          }
        }

        span {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #48B15C;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }

  .supportRight {
    .obrazek {
      width: 200px;
      display: block !important;
      margin: 0 auto;

      /* @media (min-width: 900px) {
        width: 250px;
      } */
    }
  }
`;

const SupportPanel = ({isWide, className, isPohreb}) => {
  const isPlus340 = useMediaQuery({
    query: '(min-width: 340px)'
  });

  return (
    <SupportPanelInner isWide={isWide} isPohreb={isPohreb} className={className}>
      <div className={"supportLeft"}>

        {(isPohreb) ?
          <h2>Naši specialisté vám pomohou se ve všem zorientovat</h2>
        :
          <h2>Potřebujete poradit? <br />Stačí zavolat</h2>
        }


        {(isPohreb) ?
          <p>Ztráta blízkého je velkým šokem pro každého. Jsme tu od toho, abychom vám se vším krok po kroku pomohli. Volat nám můžete 24h, 7 dní v týdnu.</p>
        :
          <p>Jsme tu pro vás 7 dní v týdnu. Ať už máte jakoukoliv otázku - stačí jen zavolat a vše vám vysvětlíme.</p>
        }

        <div className="contact">
          <a href="tel:00420315558136" className="phone">
            <img src={Phone} alt="" />
            <span>315 558 136</span>
            {(isPlus340) ?
              <>{" — Pomocná linka"}</>
            : null}
              
          </a>
          <div className="nonstop">
            <div className="greenDot"></div>
            <span>K dispozici 24/7</span>
          </div>
        </div>
      </div>
      <div className="supportRight">
        <StaticQuery
          query={graphql`
            query {
              file(relativePath: { eq: "podpora.png" }) {
                childImageSharp {
                  fixed(width: 200, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img className="obrazek" alt="Naše podpora" fixed={data.file.childImageSharp.fixed} />
          )}
        />
      </div>
    </SupportPanelInner>
  );
}

export default SupportPanel;
