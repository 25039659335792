import React, { useState } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import { useForm } from "react-hook-form";
import close from "../../assets/img/zavrit.svg";


const DemoModalInner = styled.section`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 10000;
  background: rgba(0,0,0,0.3);

  .close {
    background: none;
    border: 0;
    position: absolute;
    top: 40px;
    cursor: pointer;
    right: 40px;

    img {
      display: block;
    }
  }


  form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #DFDFDF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    padding: 40px;
    width: calc(100% - 50px - 80px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 100px);
    overflow-y: scroll;

    .odeslano {
      font-family: Visuelt-Medium;
      font-size: 20px;
      padding: 15px;
      border-radius: 5px;
      width: fit-content;
      margin: 20px 0;
      background-color: #d3e0f4;
    }


    @media (max-width: 899px) {
      padding: 25px;
      width: calc(100% - 50px - 50px);
    }

    h1 {
      font-family: NewKansas;
      font-size: 26px;
      color: #000000;
      margin-bottom: 20px;
      width: calc(100% - 70px);
    }

    p {
      padding-bottom: 20px;
      /* border-bottom: 1px solid #D2D2D2; */
    }

    label {
      display: block;
      margin-bottom: 30px;

      .errorMsg {
        font-family: Visuelt-Regular;
        line-height: 25px;
        font-size: 16px;
        color: #FF6060;
        margin: 0;
        margin-top: 8px;
      }

      span {
        display: block;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
        margin-bottom: 8px;
      }

      input[type=text] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      select {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        width: calc(100%);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border-radius: 5px;


        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=email] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=password] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=file] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        max-width: calc(330px - 4px);
        line-height: 25px;

        @media (max-width: 900px) {
          width: calc(100% - 24px);
        }

        &:focus {
          border: 2px solid #243A58;
        }
      }

      textarea {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        width: calc(100% - 24px);
        resize: none;
        height: 190px;

        &:focus {
          border: 2px solid #243A58;
        }
      }
    }

    .inputError {
      input {
        border: 2px solid #FF6060 !important;
      }

      textarea {
        border: 2px solid #FF6060 !important;
      }

      select {
        border: 2px solid #FF6060 !important;
      }

      span {
        color: #FF6060;
      }

      .fakeCheckbox {
        border: 2px solid #FF6060 !important;
      }

      p {
        color: #FF6060;

        a {
          color: #FF6060 !important;
        }
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #585858;
      line-height: 25px;
      margin-bottom: 20px;

      a {
        text-decoration: underline;
        white-space: nowrap;
        color: #585858; 
      }
    }

    .errorMessage {
      background: #FF6060;
      color: #fff;
      font-family: Visuelt-Medium;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 25px;
    }

    .submit {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #243A58;
      max-width: 200px;
      width: 100%;
      border: 0;
      padding: 13px 0;
      margin-top: 10px;
      cursor: pointer;
      height: 50px;
      border-radius: 3px;

      &:hover {
        background: #1a2b42;
      }
    }
  }
`;





const DemoModal = ({ setModal }) => {
  const { register, handleSubmit, errors, control } = useForm(); //watch
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [isDone, setIsDone] = useState(false);


  const onSubmit = data => handleForm(data);

  const handleForm = ({email, name, company, from}) => {
    setIsLoading(true);


    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }

    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }


    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then((res) => {
      axios.post(`${backendUrl}/poslimail`, {
        "to": "stepanek@goodbye.cz",
        "from": 'podpora@goodbye.cz',
        "subject": `Nová poptávka na GB Care`,
        "text": `Hurá, máme tu další<br/><br/>Jméno: ${name}<br/>E-mail: ${email}<br/>Firma: ${company}<br/>Od kud se dozvědeli: ${from}`,
        "html": `Hurá, máme tu další<br/><br/>Jméno: ${name}<br/>E-mail: ${email}<br/>Firma: ${company}<br/>Od kud se dozvědeli: ${from}`,
      },
      {
        headers: {
          "Authorization": `Bearer ${res.data.jwt}`,
          "Content-Type": "application/json"
        },
      }).then(() => {
          setIsLoading(false);
          setIsDone(true);
        }
      );
    });








    
  }




  return (
    <DemoModalInner>
      <div className="modal">
        <form onSubmit={handleSubmit(onSubmit)}>

          <h1>Domluvte si s námi nezávaznou konzultaci</h1>
          <p>Nechte nám na sebe kontakt a brzy se vám ozveme.</p>

          {isDone ? 

            <div className="odeslano">Odesláno, brzy se vám ozveme</div>
          
          : <>
            <label className={errors.name && "inputError"}>
              <span>Vaše jméno</span>
              <input ref={register({ required: true })} type="text" name="name"/>
              {errors.name && <div className="errorMsg">Toto pole je povinné</div>}
            </label>

            <label className={errors.email && "inputError"}>
              <span>E-mail</span>
              <input ref={register({ required: true })} type="email" name="email"/>
              {errors.email && <div className="errorMsg">Toto pole je povinné</div>}
            </label>

            <label className={errors.company && "inputError"}>
              <span>Název společnosti</span>
              <input ref={register({ required: true })} type="text" name="company"/>
              {errors.company && <div className="errorMsg">Toto pole je povinné</div>}
            </label>

            <label className={errors.from && "inputError"}>
              <span>Jak jste se o Goodbye Care dozvěděli?</span>

              <select ref={register({ required: true })} name="from">
                <option value=""></option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Sociální sítě">Sociální sítě</option>
                <option value="Tisk">Tisk</option>
                <option value="Podcasty">Podcasty</option>
                <option value="Osobní zkušenost">Osobní zkušenost</option>
                <option value="">Vyhledávač</option>
                <option value="Ostatní">Ostatní</option>
              </select>

              {errors.from && <div className="errorMsg">Toto pole je povinné</div>}
            </label>

            {errorMsg &&
              <p className="errorMessage">{errorMsg}</p>
            }

            <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Pokračovat"} />

 
           </>}
           
           <button className="close" onClick={() => setModal(false)}>
              <img src={close} alt=""/>
            </button>
        </form>
      </div>
    </DemoModalInner>
  );
}











export default DemoModal;
