import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";

import Zakladni from "../../assets/img/basic.svg";
import Premium from "../../assets/img/premium.svg";
import Jo from "../../assets/img/pricingCheck.svg";
import Pozadi from "../../assets/img/carePozadiPricing.svg";
import CareCtaPozadi from "../../assets/img/careCtaPozadi.svg";
import CareCtaIlustrace from "../../assets/img/careCtaIlustrace.svg";
import LinkButtonV2 from "../brandingV2/LinkButtonV2";
import Check from "../../assets/img/peceCheck.svg";



const PricingInner = styled.section`
  background: #80655c;

  .admin {
    padding: 60px 0;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 20px;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;
    margin-top: -150px;
    z-index: 3;
    position: relative;

    @media (max-width: 899px) {
      max-width: 600px;
      width: calc(100% - 50px);
    }
    

    .wrap {
      display: flex;
      width: calc(100% - 100px);
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1000px;

      @media (max-width: 899px) {
        display: block;
        width: calc(100% - 50px);
      }

      .left {
        h2 {
          font-family: NewKansas;
          font-size: 36px;
          color: #221605;
          margin-bottom: 40px;
        }
        

        ul {
          max-width: 500px;

          @media (max-width: 899px) {
            max-width: 100%;
          }


          li {
            list-style: none;
            margin-bottom: 20px;
            border: 1px solid #c1c1c1;
            border-radius: 10px;
            padding: 15px;

            div {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              span {
                font-family: Visuelt-Medium;
                font-size: 19px;
                color: #000;
                line-height: 30px;
                margin-left: 15px;
              }
            }

            p {
              font-family: Visuelt-Regular;
              font-size: 17px;
              color: #434343;
              line-height: 26px;
            }
            
          }
        }
      }

      .right {
        .gatsby-image-wrapper {
          @media (max-width: 1050px) {
            width: 285px !important;
            height: 263px !important;
          }

          @media (max-width: 950px) {
            width: 248px !important;
            height: 230px !important;
          }

          @media (max-width: 899px) {
            width: 285px !important;
            height: 263px !important;
            margin: 0 auto !important;
            display: block !important;
            max-width: 100% !important;
          }

          @media (max-width: 400px) {
            width: 260px !important;
            height: 245px !important;
          }

          @media (max-width: 360px) {
            width: 220px !important;
            height: 205px !important;
          }
        }
      }
    }
  }

  .cta {
    padding: 80px 0;
    max-width: 890px;
    margin: 0 auto;

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #fff;
      text-align: center;
      line-height: 50px;
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 28px;
      margin-bottom: 40px;
      margin-top: 30px;
    }

    .linkButton button {
      background: #fff;
      color: #261611;
      margin: 0 auto;
    }
  }
`;


const Pricing = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });

  return (
    <PricingInner id="proHR">
      <div className="admin">
        <div className="wrap">
          <div className="left">
            <h2 data-aos="new-animation" data-aos-delay="000">Jak to vypadá na straně vaší společnosti</h2>
            <ul>
              <li data-aos="new-animation" data-aos-delay="200">
                <div>
                  <img src={Check} alt="check" />
                  <span>Jednoduchá implementace</span>
                </div>
                <p>Velice jednoduchá implementace služby do vaší společnosti, OP a fakturace. Nemusíte cokoliv kódovat ani měnit vaše procesy.</p>
              </li>

              <li data-aos="new-animation" data-aos-delay="400">
                <div>
                  <img src={Check} alt="check" />
                  <span>Real-time statistiky o používatelnosti</span>
                </div>
                <p>V reálném čase se ujistíte, že vaši zaměstanci služby opravdu využívají, kolik jim už ušetří času a jak jim pomáhají.</p>
              </li>

              <li data-aos="new-animation" data-aos-delay="600">
                <div>
                  <img src={Check} alt="check" />
                  <span>Rychlé zavedení</span>
                </div>
                <p>Od podpisu objednávky službu ve vaší společnosti zprovozníme v průměru do 10 dnů.</p>
              </li>
            </ul>
          </div>

          <StaticQuery
            query = {graphql`
              query {
                careMockup: file(relativePath: { eq: "careMockup.png" }) {
                  childImageSharp {
                    fixed(height: 330, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }

              }
            `}
            render={data => (
              <div className="right">
                <Img
                  fixed={data.careMockup.childImageSharp.fixed}
                  alt="Goodbye Care"
                />
              </div>
            )}
          />
        </div>
      </div>

      <div className="cta">
        <h2 data-aos="new-animation" data-aos-delay="000">Podívejte se, jak by to mohlo fungovat ve vaší firmě</h2>
        <p data-aos="new-animation" data-aos-delay="200">Postarejte se o zaměstnance v nesnázích. Vzbuďte ve vašem týmu pocit, že je nenecháte na holičkách a dejte jim podporu, kterou si zaslouží.</p>
        <LinkButtonV2 func={() => setModal(true)} text="Domluvit ukázku služby" />
      </div>
    </PricingInner>
  );
}


export default Pricing;
