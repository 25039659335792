import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";
import Phone from "../../assets/img/telefon.svg";
import Sipka from "../../assets/img/sipkaW.png";
import OverenaFirma from "../pohrby/OverenaFirma";
import Seznam from "../../assets/img/seznam.png";
import Forbes from "../../assets/img/forbes.png";
import iDnes from "../../assets/img/idnes.png";
import PozitivniZpravy from "../../assets/img/pozitivniZpravy.png";
import E15 from "../../assets/img/e15trans.png";
import StartupJobs from "../../assets/img/startupJobs.png";
import CzechCrunch from "../../assets/img/ccLogo.svg";
import HN from "../../assets/img/hn_logo.svg";
import Denik from "../../assets/img/denik.png";
import Aktualne from "../../assets/img/aktualne.png";


const JumbotronInner = styled.section`
  
  .jumboTopWrap {
    position: relative;
    
    .pozadi {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #F4F3F0;
    }
  }

  .jumboTop {
    max-width: 1150px;
    margin: 0 auto;
    padding-top: 80px;
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 170px;
    z-index: 1;
    position: relative;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
      max-width: 600px;
      padding-top: 30px;
      padding-bottom: 200px;
      display: block;
    }

    h1 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-family: NewKansas;
      font-size: 50px;
      color: #000000;
      line-height: 69px;

      .nowrap {
        white-space: nowrap;
        font-family: NewKansas;
      }

      @media (max-width: 1200px) {
        font-size: 37px;
        line-height: 53px;
      }

      @media (max-width: 899px) {
        font-size: 29px;
        color: #000000;
        line-height: 42px;
      }
    }


    p {
      margin-bottom: 40px;
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: rgba(0,0,0,0.70);
      line-height: 28px;

      @media (max-width: 899px) {
        font-size: 16px;
        line-height: 25px;
      }
    }

    .left {
      max-width: 700px;
      width: calc(100% - 370px);

      @media (max-width: 899px) {
        max-width: none;
        width: 100%;
      }
    }

    .right {
      @media (max-width: 899px) {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: -170px;
      }

      @media (max-width: 440px) {
        width: 290px !important;
        height: 242px !important;
      }
    }
  }

  .jumboBot {
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    width: calc(100% - 100px - 60px);
    max-width: 1150px;
    margin: 0 auto;
    margin-top: -80px;
    padding: 30px;
    z-index: 1;
    position: relative;

    @media (max-width: 899px) {
      width: calc(100% - 50px - 60px);
      max-width: 600px;
    }

    @media (max-width: 390px) {
      width: calc(100% - 20px - 40px);
      padding: 20px;
    }

    h2 {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;

      @media (max-width: 899px) {
        text-align: left;
      }
    }

    ul {
      display: block;
      margin: 0 auto;
      width: calc(100%);
      text-align: center;


      @media (max-width: 899px) {
        text-align: left;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 30px;
        margin-top: 20px;



        &:last-child {
          margin-right: 0px;

        }

        &:hover {
          img {
            filter: grayscale(100%) brightness(0%) opacity(60%);
          }
        }

        img {
          filter: grayscale(100%) brightness(0%) opacity(35%);
          max-height: 20px;
          width: auto;

          @media (max-width: 380px) {
            max-height: 18px;
          }
        }

        .jobs {
          height: 16px;

          @media (max-width: 380px) {
            max-height: 14px;
          }
        }

        .seznam {
          max-height: 24px;

          @media (max-width: 380px) {
            max-height: 22px;
          }
        }

        .aktualne {
          max-height: 35px;

          @media (max-width: 380px) {
            max-height: 30px;
          }
        }
      }
    }
  }
  
`;





const JumbotronCare = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });








  return (
    <StaticQuery
      query = {graphql`
        query {
          careJumbo: file(relativePath: { eq: "careJumbo.png" }) {
            childImageSharp {
              fixed(height: 330, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          careJumboPozadi: file(relativePath: { eq: "careJumboPozadi.png" }) {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <JumbotronInner>
          <div className="jumboTopWrap">
            <div className="jumboTop">
              <div className="left">
                <Breadscrum
                  visible
                  stranky={[
                    {name: "Domů", url: "/"},
                    {name: "Goodbye Care", url: "/care/"},
                  ]}
                />
              
                <h1>Podpořte své zaměstnance <span className="nowrap">v těch</span> nejtěžších chvílích</h1>
                <p>Nabízíme aplikaci a 24/7 asistenční službu, která pomůže lidem ve vašem týmu a jejich rodinám se zorientovat a vyřešit všechny úkoly související se smrtí jejich blízkého.</p>
                <LinkButton func={() => setModal(true)} to="https://calendly.com/jiristepanek" text="Domluvit ukázku služby" />
                
              </div>

              <Img className="right" alt={"Goodbye Care"} fixed={data.careJumbo.childImageSharp.fixed} />
            </div>

            <Img className={"pozadi"} alt={""} fluid={data.careJumboPozadi.childImageSharp.fluid} />
          </div>

          <div className="jumboBot">
            <h2>Můžete nás znát z:</h2>
            <ul>   
              <li>
                <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
                  <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
                  <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://zpravy.aktualne.cz/domaci/ekologicke-pohrbivani/r~a321b27ed6a911eca9eeac1f6b220ee8/">
                  <img className="aktualne" src={Aktualne} alt="Pohřeb bez rakve, do lesa i na louku. Za pár let běžné, věří expert ministerstva"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
                  <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://cc.cz/moderni-pohreb-na-par-kliknuti-cesky-startup-goodbye-ziskava-na-rozvoj-sve-sluzby-miliony-korun/">
                  <img className="jobs" src={CzechCrunch} alt="Moderní pohřeb na pár kliknutí. Český startup Goodbye získává na rozvoj své služby miliony korun"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://www.idnes.cz/zpravy/domaci/data-internet-uzivatele-spolecnost-nabidka-sluzby-pohreb-umrti-online-zavet-notari.A220413_151632_domaci_lre">
                  <img src={iDnes} alt="Digitální pozůstalost. Uspořádat je třeba účty na sítích, potíž je s kryptoměnami"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
                  <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
                </a>
              </li>

              <li>
                <a target="_blank" href="https://brnensky.denik.cz/zpravy_region/pohrby-v-brne-vedou-kremace-s-obradem-pritomnost-kneze-chce-jen-17-lidi-20220425.html">
                  <img src={Denik} alt="Pohřby v Brně: vedou kremace s obřadem. Přítomnost kněze chce jen 17% lidí"/>
                </a>
              </li>
            </ul>
          </div>

        </JumbotronInner>
      )}
    />
  );
}











export default JumbotronCare;
