import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { StaticQuery, graphql, Link } from "gatsby";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../Breadscrum";
import LinkButton from "../pohrby/LinkButton";

import careProblem1 from "../../assets/img/careProblem1.svg";
import careProblem2 from "../../assets/img/careProblem2.svg";
import careProblem3 from "../../assets/img/careProblem3.svg";
import careProblem4 from "../../assets/img/careProblem4.svg";
import careProblem5 from "../../assets/img/careProblem5.svg";
import careProblem6 from "../../assets/img/careProblem6.svg";
import careProblems from "../../assets/img/careProblems.svg";


import careIlustration from "../../assets/img/careIlustration.svg";


const ProblemsInner = styled.section`
  width: 100%;
  position: relative;
  padding: 60px 0;
  padding-bottom: 0;

  .content {
    position: relative;
    z-index: 2;

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #142743;
      text-align: center;
      line-height: 50px;
      margin-bottom: 30px;

      @media (max-width: 899px) {
        margin: 0 auto;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 25px;
        width: calc(100% - 50px);
        max-width: 600px;
      }

      @media (max-width: 430px) {
        font-size: 25px;
        line-height: 33px;
        text-align: left;
      }
    }

    .desc {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #142743;
      margin: 0 auto;
      text-align: center;
      line-height: 28px;
      margin-bottom: 60px;
      max-width: 700px;
      width: calc(100% - 100px);

      @media (max-width: 899px) {
        width: calc(100% - 50px);
        max-width: 600px;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }

    .bubble {
      background: #2B4866;
      border-radius: 20px;
      max-width: 1280px;
      width: calc(100% - 50px);
      margin: 0 auto;
      padding-top: 45px;
      padding-bottom: 25px;

      @media (max-width: 680px) {
        max-width: 600px;
        width: calc(100% - 20px);
      }

      ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1150px;
        width: calc(100% - 50px);

        @media (max-width: 1100px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 50px;
          width: calc(100% - 100px);
        }

        @media (max-width: 680px) {
          grid-template-columns: 1fr;
          grid-gap: 50px;
          width: calc(100% - 30px);
        }

        li {
          max-width: 250px;
          width: calc(100%/4 - 10px);

          @media (max-width: 1100px) {
            width: 100%;
            max-width: 100%;
          }

          span {
            display: block;

            &:first-child {
              font-family: NewKansas-Bold;
              font-size: 41px;
              color: #FFFFFF;

              @media (max-width: 430px) {
                font-size: 36px;
              }
            }

            &:last-child {
              font-family: Visuelt-Regular;
              font-size: 17px;
              color: #FFFFFF;
              line-height: 27px;
              opacity: 0.8;
              margin-top: 9px;

              @media (max-width: 430px) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }

      p {
        opacity: 0.34;
        font-family: VisueltPro-Regular;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
        margin-top: 35px;
        width: calc(100% - 50px);
        margin-left: auto;
        margin-right: auto;

        a {
          color: #FFFFFF;
        }
      }
    }
  }

  .background {
    background: #E1E5E9;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 100px);
    width: 100%;
    z-index: 1;

    @media (max-width: 680px) {
      overflow: hidden;
    }

    img {
      width: 95%;
      margin: 0 auto;
      display: block;
      margin-top: 20px;

      @media (max-width: 680px) {
        width: 1200px;
        max-width: none;
        opacity: 0.5;
      }
    }
  }
`;





const Problems = ({ setModal }) => {
  const isPlus900 = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const isPlus1000 = useMediaQuery({
    query: '(min-width: 1000px)'
  });

  return (
    <StaticQuery
      query = {graphql`
        query {
          careProblemPozadi: file(relativePath: { eq: "careProblemPozadi.png" }) {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          sadGirl: file(relativePath: { eq: "sadGirl2.jpg" }) {
            childImageSharp {
              fixed(height: 400, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <ProblemsInner id="dopad">
          <div className="content">
            <h2 data-aos="new-animation" data-aos-delay="0">Pár dní volna na pohřeb? To nestačí.</h2>
            <p className="desc" data-aos="new-animation" data-aos-delay="100">Ztráta blízkého je ta největší psychická výzva v našem životě.<br />Bohužel ani ty nejúspěšnější týmy se jí nevyhnou.<br /><br />V dnešním světě zaměstanci požadují řadu benefitů pro jejich duševní a fyzické zdraví. Na ničem jim ale nezáleží více, jakmile musí začít řešit tak stresující záležitost.</p>
            <div className="bubble">
              <ul>
                <li data-aos="new-animation" data-aos-delay="200">
                  <span>26%</span>
                  <span>zaměstnanců ztratí blízkého každý rok. 57% potom lidí prožívá klinické příznaky stresu.</span>
                </li>
                <li data-aos="new-animation" data-aos-delay="400">
                  <span>420 hodin</span>
                  <span>zabere vyřešit záležitosti ohledně úmrtí blízkého. Vše dohromady trvá 13-20 měsíců.</span>
                </li>
                <li data-aos="new-animation" data-aos-delay="600">
                  <span>80%</span>
                  <span>zaměstnancům smrt negativně ovlivňuje pracovní výkon. Ztratí v průměru 1 hodinu denně.</span>
                </li>
                <li data-aos="new-animation" data-aos-delay="800">
                  <span>14%</span>
                  <span>zaměstnanců (30-44 let) přemýšlí o výpovědi z práce po smrti svého blízkého.</span>
                </li>
              </ul>
              <p>Zdroje: <a href="https://www.mckinsey.com/business-functions/people-and-organizational-performance/our-insights/the-hidden-perils-of-unresolved-grief">McKinsey & Company</a>, <a href="https://www.chicagotribune.com/news/ct-xpm-2003-08-20-0308200089-story.html">Chicago Tribune</a>, <a href="https://www.prnewswire.com/il/news-releases/empathy-releases-annual-cost-of-dying-report-2022-301494823.html">Workforce Institute Engagement Report</a></p>
            </div>
          </div>
          
          <div className="background">
            <img src={careIlustration} alt="" />
          </div>
        </ProblemsInner>
      )}
    />
  );
}











export default Problems;
